.hd-projects-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hd-projects-list > * {
  width: 48.5%;
  margin-bottom: 1em;
}

@media(max-width: 600px) {
  .hd-projects-list {
    display: block;
  }
}
