.hd-project-header {
  cursor: default;
  position: relative;
  padding-bottom: 2em;
  white-space: nowrap;
  text-transform: uppercase;
  margin: 0;
}

.hd-project-header span {
  font-weight: 200;
  margin: 0 0 0 .75rem;
  font-size: 3rem;
  padding: .375rem .5rem .5rem .75rem;
  letter-spacing: 4px;
  color: #F2F2F2;
  background-color: #4A4C59;
}

.hd-project-header .line {
  margin: 0;
  width: 100%;
  position: relative;
  top: 32px;
  z-index: -1;
  background-color: #4A4C59;
}

.hd-project-back {
  font-family: "Roboto Slab", "Times New Roman", Times, serif;
  display: block;
  position: absolute;
  right: 0;
  color: #4A4C59;
  text-decoration: none;
  text-transform: uppercase;
  padding: .5em 1em .5em .5em;
  top: 1.75em;
}

.hd-project-back-chevron {
  position: relative;
  top: -3px;
  right: -2px;
  font-size: .75em;
}

.hd-project-back:hover {
  color: #D91E1E;
}

.hd-project-header.hd-project-subheader {
  padding-bottom: 0.5em;
}

.hd-project-header.hd-project-subheader span {
  font-family: "Roboto Condensed", Helvetic, sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  padding: .125em .375em .125em .375em;
}

.hd-project-header.hd-project-subheader-2 span {
  background: #F2F2F2;
  border: 2px solid #4A4C59;
  color: #4A4C59;
  font-size: 1.125em;
}

.hd-project-header.hd-project-subheader-3 {
  text-align: center;
}

.hd-project-header.hd-project-subheader-3 span {
  background: 0;
  color: #4A4C59;
  font-size: 1em;
  padding-left: .625em;
}

.hd-project-header.hd-project-subheader .line {
  height: 4px;
  top: 18px;
}

.hd-project-header.hd-project-subheader-2 .line {
  height: 2px;
  top: 14px;
}

.hd-masthead  {
  text-decoration: none;
  display: block;
}

.hd-masthead .hd-header {
  margin: 0;
  padding: .75em 0;
  font-size: 1.25rem;
  opacity: 0.5;
  transition: opacity .25s .1s;
  cursor: pointer;
}

.hd-masthead .hd-header:hover {
  opacity: 1;
}

.hd-masthead .hd-header .hd-dot {
  color: #0D0D0D;
}



@media(max-width: 600px) {
  .hd-project .hd-project-header {
    font-size: 16px;
    margin-bottom: .25em;
    margin-top: .25rem;
  }

  .hd-project-back {
    top: 3.25em;
  }
}
