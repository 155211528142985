.hd-editor.hd-bounce {
  padding-top: 0;
}

.hd-bounce .hd-editor-preview-canvas {
  max-width: 900px;
}

.hd-bounce-example {
  flex-direction: column;
}

.hd-bounce-example .hd-editor-preview-canvas {
  max-width: inherit;
  min-height: 0;
  margin: 0;
}

.hd-bounce-example .hd-editor-preview-canvas canvas {
  box-sizing: border-box;
}

.hd-bounce .hd-editor-controls {
  text-align: center;
}

.hd-bounce .hd-editor-controls input[type="button"] {
  padding-top: .375em;
  width: 90px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: .875em;
}

.hd-bounce .hd-editor-controls input[type="button"] + input[type="button"] {
  margin-left: 1em;
}
