.input-container {
  padding: .25em .5em;
}

.input {
  display: flex;
  align-items: center;
  padding: .25em 0;
}

.input-label {
  text-align: left;
  width: 100px;
  flex-grow: 2;
  font-family: "Roboto Slab", "Times New Roman", Times, serif;
  font-weight: 700;
  font-size: 18px;
}

.input-input {
  flex-grow: 1;
  border: 2px solid #4A4C59;
  border-radius: 6px;
  padding: .25em;
}

.input-input:focus {
  border: 2px solid #058229;
  outline: 0;
}

.input-number .input-input {
  text-align: right;
  width: 75px;
}

.input-helper {
  text-align: left;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  max-width: 300px;
  width: auto;
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 1.25em;
}

input[type="button"] {
  cursor: pointer;
  width: 80%;
  margin: .5em 0;
  font-family: "Roboto Slab", "Times New Roman", Times, serif;
  font-weight: 700;
}

input[type="button"]:hover {
  background: #1ED953;
}
