.hd-section {
  min-height: 33vh;
}

.hd-section {
  width: 80%;
  margin: 0 auto;
  margin-top: 3em;
}

.hd-section-header {
  margin-bottom: 2em;
}

.hd-section-header .line {
  margin: 0;
  width: 100%;
  position: relative;
  top: 32px;
  z-index: -1;
  background-color: #4A4C59;
}

.hd-section-header span {
  font-weight: 200;
  text-transform: uppercase;
  margin: 0 .5em 0;
  font-size: 48px;
  padding: .5rem 1.25rem .625rem 1.5rem;
  letter-spacing: 4px;
  color: #F2F2F2;
  background-color: #4A4C59;
}
