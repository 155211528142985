.hd-header {
  font-size: 256px;
  position: relative;
  cursor: default;
  font-weight: 200;
  text-transform: uppercase;
  margin: 14vh auto 0em;
  text-align: center;
}

.hd-header > div {
  display: inline-block;
  padding: 2rem 2.75rem;
  border: #0F4C73 solid 10px;
  width: 2.5em;
  background: #fcfcfc;
}

.hd-header .hd-heyer {
  color: #0D0D0D;
}

.hd-header .hd-dot {}

.hd-header .hd-dev {
  color: #4A4C59;
}

.coming-soon {
  text-align: center;
  color: #F2F2F2;
  background-color: #0F4C73;
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 48px;
  padding: .5rem 1.25rem .625rem 1.5rem;
  letter-spacing: 4px;
  width: auto;
  display: block;
  position: absolute;
  top: -.625em;
  left: 50%;
  margin-left: -104px;
}


@media(max-width: 600px) {
  .hd-main {
    min-height: 2em;
    font-size: 128px;
  }

  .hd-header {
    font-size: 128px;
    margin-top: .5em;
  }
}
