.hd-project-item {
  border: 2px solid #4A4C59;
  border-radius: 6px;
  background: #fcfcfc;
  text-decoration: none;
  border-bottom-width: 6px;
  transition: border-color .25s;
}

.hd-project-item:hover {
  border-color: #0D0D0D;
}

.hd-project-item .hd-project-name {
  padding: .25em .5em .375em;
  text-decoration: none;
  display: block;
  background-color: #4A4C59;
  color: #fcfcfc;
  font-family: "Roboto Slab", "Times New Roman", Times, serif;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1px;
  transition: background-color .25s;
}

.hd-project-item:hover .hd-project-name,
.hd-project-item:hover .hd-project-tag {
  background-color: #0D0D0D;
}

.hd-project-item .hd-project-description {
  padding: 1em;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 1.25em;
  color: #0D0D0D;
}

.hd-project {
  width: 80%;
  margin: .5em auto 1.5em auto;
}

.hd-project-tags {
  padding: 0 0 .5em .5em;
}

.hd-project-tag {
  text-decoration: none;
  display: inline-block;
  background-color: #4A4C59;
  color: #F2F2F2;
  margin: 0.25em .5em;
  padding: .5em 1em;
  line-height: 1em;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  font-family: "Source Code Pro", "Courier New", Courier, monospace;
  transition: background-color .125s .08s;
}

.hd-project-item:hover .hd-project-tag:hover {
  background-color: #0768A6;
  color: #F2F2F2;
}

.hd-project-content {
  margin-bottom: 2em;
}

.hd-project-learn {
  font-family: Helvetica, Arial, sans-serif;
  padding: .5em 1.5em 1.5em;
  line-height: 1.5em;
}

.hd-editor {

}

.hd-editor-inputs {
  min-width: 100px;
}

.hd-editor-inputs label {
  text-algin: left;
}

.hd-editor {
  display: flex;
  padding: 1em;
}

.hd-editor-preview,
.hd-editor-preview-canvas {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  overflow: auto;
  margin: .5em 0 .5em 1em;
}

.hd-editor-preview,
.hd-editor-preview-canvas canvas {
  border: 2px solid #4A4C59;
  border-radius: 6px;
  background-color: #ffffff;
}

.hd-editor-preview canvas {
  position: relative;
  flex-grow: 0;
}

@media(max-width: 600px) {
  .hd-project {
    margin: 0;
    padding: .5em;
    width: 100%;
  }

  .hd-editor {
    display: block;
    padding: 0;
  }

  .hd-editor-preview {
    margin: 0;
    padding: .5em;
  }

  .hd-editor-inputs {
    padding-bottom: 1em;
  }

  .hd-editor-inputs .input {
    flex-direction: col;
  }

  .hd-editor-inputs .input-helper {
    max-width: 100%;
    font-size: 11px;
    text-align: right;
  }

  .hd-editor-inputs .input-label {
    font-size: 14px;
  }

  .hd-editor-inputs .input-number .input-input {
    font-size: 12px;
    padding: .125em .5em;
    width: 100%;
  }
}
