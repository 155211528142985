* {
  box-sizing: border-box;
}

.white { color: #F2F2F2; }
.gray { color: #4A4C59; }
.black { color: #0D0D0D; }
.red-light { color: #D91E1E; }
.red-light-bg { background: #D91E1E; }
.red-dark { color: #A60505; }
.red-dark-bg { background: #A60505; }
.blue-light { color: #0768A6; }
.blue-light-bg { background: #0768A6; }
.blue-dark { color: #0F4C73; }
.blue-dark-bg { background: #0F4C73; }
.green-light { color: #1ED953; }
.green-light-bg { background: #1ED953; }
.green-dark { color: #058229; }
.green-dark-bg { background: #058229; }

body {
  font-size: 16px;
  margin: 0;
  font-family: "Source Code Pro", "Courier New", Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F2F2;
  color: #0D0D0D;
}

a {
  color: #0768A6;
}

a:hover {
  color: #0f4c73;
}

h1, h2, h3, h4, h5 {
  font-family: "Six Caps", Helvetica, sans-serif;
}

.hd-main {
  min-height: 60vh;
}





.hd-header-about span {
  background-color: #A60505;
}

.hd-header-about .line {
  background: #A60505;
}

.hd-header-projects span {
  background-color: #058229;
}

.hd-header-projects .line {
  background: #058229;
}

.hd-section-content {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  line-height: 1.5em;
  padding: 0 1.5em 0 1.5em;
}

.hd-footer {
  width: 60%;
  margin: 0 auto;
  font-size: 10px;
  text-align: center;
  padding: 8em 0 3em 0;
}

.hd-copyright {
  line-height: 1.5em;
}

.hd-footer-links a,
.hd-footer-links svg {
  width: 32px;
  height: 32px;
}

.hd-footer svg {
  fill: #4A4C59;
}

.hd-footer a:hover svg {
  fill: #0768A6;
}

.hd-footer-links {
  margin-bottom: 1em;
}

.hd-footer a {
  color: #0F4C73;
  text-decoration: none;
}

.hd-footer a:hover {
  color: #0768A6;
}

.line {
  height: 8px;
  font-size: 256px;
  width: 2.5em;
  margin: 4px auto 0;
}

@media(max-width: 600px) {
  html {
    font-size: 12px;
  }

  .hd-main {
    min-height: 2em;
    font-size: 128px;
  }

  .hd-section {
    min-height: 2em;
    height: auto;
  }

  .hd-section-header span {
    font-size: 32px;
    padding: 0rem .5rem 0 .75rem;
  }

  .hd-section-header .line {
    top: 16px;
  }

  .line {
    font-size: 128px;
  }

  .hd-section-content {
    font-size: 14px;
    padding: 0;
  }

  .hd-project-item {
    width: 100%;
    margin-bottom: 1em;
  }

  .hd-project-item .hd-project-name {
    font-size: 14px;
    padding-top: .125em;
    padding-bottom: .25em;
  }

  .hd-project-item .hd-project-description {
    font-size: 12px;
  }

  .hd-project-back {
    font-size: 12px;
  }

  .hd-project-learn {
    padding: 0 .25em;
    font-size: 12px;
  }
}
