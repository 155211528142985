.hd-sitemap ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hd-sitemap li {
  list-style: none;
  width: 33%;
  display: block;
}

.hd-sitemap a {
  color: #0F4C73;
  text-decoration: none;
  font-weight: bold;
}

.hd-sitemap a:hover {
  color: #0768A6;
}

.hd-sitemap-stories li {
  white-space: nowrap;
  width: 48%;
}

.hd-sitemap-stories ul li a {
  display: inline-block;
  padding-bottom: .5em;
}

.hd-sitemap-stories li li {
  padding-bottom: 0em;
  font-size: 14px;
  min-width: 100%;
}

.hd-sitemap-stories li ul {
  padding-left: 0;
}

.hd-sitemap-stories li li a {
  display: inline-block;
  min-width: 50%;
  padding-bottom: 2px;
}

.hd-sitemap-stories li li a + a {
  padding-left: 1em;
}

.hd-sitemap-tag-count, .hd-sitemap-link-tag {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  top: -2px;
  margin-left: .5em;
  font-size: 9px;
  border-radius: 1em;
  line-height: 1em;
  padding: 3px 8px 4px 8px;
  background-color: #4A4C59;
}
